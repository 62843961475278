<template>
  <FormWrapper :is-loading="isLoading">
    <PageTitle title="Deduction List" slot="title" link="/helpContent/DeductionList" />
        <FormRow>
            <div class="col-sm-2">
              <Year label="Year" v-model="filtering.year" rules="required" />
            </div>
            <div class="col-sm-2" >
              <InputMonth
                label="Month"
                :items="monthsList"
                item-name="NAME"
                item-value="id"
                v-model="filtering.month"
              />
            </div>
            <div class="col-sm-3">
                <FormButton style="margin-top: 5px" type="primary" @click="search" :rounded="true">
                    <Icon type="ios-search" />
                </FormButton>
            </div>
        </FormRow>
    <DataTable
      :actions="true"
      :data="listData"
      :columns="tableConfig"
      :is-loading="isLoading"
      @on-selection-change="selectChange"
      :pagination="pagination"
      @on-page-change="pageChange"
      @on-sort-change="sortChange"
    >
      <template slot="actionsLeft">
        <TableButton @click="() => add()">
          <img :src="require(`Assets/images/plus-1.png`)"/>
          Add
        </TableButton>
        <TableButton @click="() => edit()">
          <img :src="require(`Assets/images/edit-1.png`)" />
          Edit
        </TableButton>
        <TableButton @click="() => remove()">
          <img :src="require(`Assets/images/del.png`)"/>
          Remove
        </TableButton>
      </template>
    </DataTable>
    <Popup
      title="Information"
      :value="warningModal.visible"
      @close="() => showWarningModal(false)"
    >
      {{ warningModal.message }}
      <div slot="footer">
        <FormButton
          v-if="warningModal.buttons.cancel"
          @click="() => showWarningModal(false)"
          >Cancel
        </FormButton>
        <FormButton
          v-if="warningModal.buttons.confirm"
          type="success"
          @click="confirmDelete"
          >Confirm
        </FormButton>
        <FormButton
          v-if="warningModal.buttons.ok"
          @click="() => showWarningModal(false)"
          >Ok</FormButton
        >
      </div>
    </Popup>
  </FormWrapper>
</template>

<script>
  import FormWrapper from "Components/form/FormWrapper";
  import PageTitle from "Components/layout/PageTitle";
  import DataTable from "Components/DataTable";
  import loadingMixin from "Mixins/loadingMixin";
  import validateSelectedRows from "Mixins/validateSelectedRows";
  import { getDeductionList,removeStaffDeduction } from "../api";
  import tableConfig from "./table";
  import Year from "../../../components/Year";
  export default {
    name: "List",
    mixins: [loadingMixin,validateSelectedRows],
    components: {
      FormWrapper,
      DataTable,
      PageTitle,
      Year,
    },
    computed: {
      userData() {
        return this.$store.state.user.user;
      },
    },
    data() {
      return {
        filtering: {
          year: new Date().getFullYear(),
          month: new Date().getMonth() + 1,
          selectedStaff: null,
        },
        staffList: [],
        monthsList: [],
        sorting: {
          key: "id",
          order: "asc",
        },
        pagination: {
          perPage: 10,
          page: 1,
          total: 0,
        },
        tableConfig,
        listData: [],
        selectedRows: [],
        showMessage: {
          isVisible: false,
          message: "",
        },
      };
    },
    created() {
      this.getDataByFilter();
    },
    methods: {
      add() {
        this.$router.push({ path: "/addDeduction" });
      },
      edit() {
        if (this.validateSelectedLength(1)) {
          this.$router.push({
            path: `/deduction/edit/${btoa(this.selectedRows[0].id)}`,
          });
        }
      },
      remove() {
        if (this.validateSelectedLength(1)) {
          if (this.deleteSelectedValue()) {
          }
        }
      },
      confirmDelete() {
        this.showWarningModal(false);
        const data = {
          id: this.selectedRows[0].id,
        };
        removeStaffDeduction(data).then((res) => {
          if (res.data[0].status > 1) {
            this.showWarningModal(true);
            this.warningModal.buttons.ok = true;
            this.warningModal.buttons.cancel = false;
            this.warningModal.buttons.confirm = false;
            this.warningModal.message = res.data[0].message;
            this.selectedRows = [];
          } else {
            this.selectedRows = [];
            this.warningModal.buttons.ok = true;
            this.warningModal.buttons.cancel = false;
            this.warningModal.buttons.confirm = false;
            this.showMessage.isVisible = true;
            this.showMessage.message = res.data[0].message;
          }
        });
        this.getDataByFilter();
      },
      search() {
        this.pagination.page = 1;
        this.getDataByFilter();
      },
      selectChange(data) {
        this.selectedRows = data;
      },
      sortChange(data) {
        this.sorting.key = data.key;
        this.sorting.order = data.order;
        this.getDataByFilter();
      },
      getDataByFilter() {
            setTimeout(() => {
              // this.showLoader();
              const { key, order } = this.sorting;
              const { perPage, page } = this.pagination;
              
              const filters = {};
              filters.year = this.filtering.year;
              filters.month = this.filtering.month;
              filters.selectedStaff = this.filtering.selectedStaff;
              const data = {
                filterjson: {
                  filter: [filters],
                  sort: [{ key, order }],
                  paging: [
                    {
                      startIndex: page,
                      pagesize: perPage,
                    },
                  ],
                },
              };

              getDeductionList(data)
                .then(this.handleResponse)
                .catch(this.handleError);
            }, 50);
      },
      pageChange(page) {
        this.pagination.page = page;
        this.getDataByFilter();
      },
      handleResponse(response) {
        this.hideLoader();
        this.listData = response.data;
        this.pagination.total = response.NumberOfRecords;
      },
    },
  };
</script>

<style lang="scss" module>
  .popupRow {
    padding: 5px 0 15px;
  }
  .pagination {
    margin: 10px 0 0;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    line-height: 26px;
  }

  .paginationText {
    margin: 0 20px;
  }

  .paginationButton {
    cursor: pointer;
    width: 26px;
    text-align: center;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
      box-shadow 200ms ease;
  }
  .paginationButton:hover {
    background: #57a3f3;
    color: #fff;
    &:hover {
      box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
        0 4px 15px 0 rgba(0, 0, 0, 0.15);
    }
  }
</style>
